import classnames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Chip, Tooltip, SimpleSelectSingle } from "@bbdevcrew/bb_ui_kit_fe";

import DeleteConfirmation from "@components/_common/DeleteConfirmation/DeleteConfirmation";
import ColorDot from "@components/_common/ColorDot";

import s from "./ItemCard.module.less";

import { ICON_MAPPING } from "./helpers";
import { IItemCardProps, IItemAction } from "./types";

import { MoreActionsIcon } from "@assets/index";

const ItemCard: React.FC<IItemCardProps> = ({
  name,
  message,
  isDefault,
  tooltip,
  actions = [],
  tags,
  onItemClick,
  color,
}) => {
  const { t } = useTranslation();

  const [confirmationAction, setConfirmationAction] = useState<IItemAction>();

  const renderActions = () => {
    if (!actions.length) {
      return null;
    }

    const handleSelect = (key: string) => {
      const action = actions.find(a => a.key === key);
      if (action) {
        if (action.confirmationText) {
          setConfirmationAction(action);
        } else {
          action.onActionClick();
        }
      }
    };

    return (
      <div className={s.bbModalItemCardContainerAction}>
        <SimpleSelectSingle
          options={actions.map(action => ({
            value: action.key,
            label: t(action.label),
            icon: ICON_MAPPING[action.key],
          }))}
          onChange={value => {
            handleSelect(value as string);
          }}
          trigger={() => (
            <button className={s.bbModalItemCardContainerActionTrigger}>
              <MoreActionsIcon />
            </button>
          )}
          minWidth={120}
          className={s.bbModalItemCardContainerActionMenu}
        />
      </div>
    );
  };

  return (
    <div className={s.bbModalItemCard}>
      <div
        className={classnames(s.bbModalItemCardContainer, {
          [s.bbModalItemCardContainerClickable]: onItemClick,
        })}
      >
        <Tooltip title={tooltip} mouseEnterDelay={0.2}>
          <div
            onClick={() => onItemClick && onItemClick(message || name)}
            className={s.bbModalItemCardContainerBody}
          >
            <div className={s.bbModalItemCardContainerBodyTitle}>
              {color && <ColorDot color={color} />}
              {name}
              {isDefault && (
                <span className={s.bbModalItemCardContainerBodyDefaultChip}>
                  {t("generic:default")}
                </span>
              )}
            </div>
            {message && <div className={s.bbModalItemCardContainerBodyDescription}>{message}</div>}
            {!!tags?.length && (
              <div className={s.bbModalItemCardTags}>
                {tags.map(({ id, label }) => (
                  <Chip key={id} _size="xs" text={label} _type="white" />
                ))}
              </div>
            )}
          </div>
        </Tooltip>

        {renderActions()}
      </div>
      {confirmationAction?.confirmationText && (
        <DeleteConfirmation
          message={confirmationAction.confirmationText}
          onConfirm={() => confirmationAction?.onActionClick()}
          onCancel={() => setConfirmationAction(undefined)}
        />
      )}
    </div>
  );
};

export default ItemCard;
