import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { SentimentChart } from "./SentimentChart";
import PerformanceCard from "../../../_common/performanceCard";

import s from "./SentimentsCard.module.less";

import { ISentimentsTrendProps } from "./SentimentCard.type";

import SmtTotal from "@assets/sentiment/SentimentTotal.svg";
import SmtNeutral from "@assets/sentiment/SentimentNeutral.svg";
import SmtPositive from "@assets/sentiment/SentimentPositive.svg";
import SmtNegative from "@assets/sentiment/SentimentNegative.svg";

const SentimentsTrend: FC<ISentimentsTrendProps> = ({ fullWidth, suffix = null, ...props }) => {
  const { t } = useTranslation();

  const getSentimentIcon = () => {
    switch (props.type) {
      case "total":
        return <SmtTotal data-icon="comment" />;
      case "positive":
        return <SmtPositive data-icon="comment-positive" />;
      case "negative":
        return <SmtNegative data-icon="comment-negative" />;
      case "neutral":
        return <SmtNeutral data-icon="comment-neutral" />;
      default:
        return <SmtNegative />;
    }
  };

  const getSentimentText = () => (
    <>
      {t(`generic:sentiment:${props.type}`)}
      <p className={s.bbSentimentText}>{t("generic:conversations")}</p>
    </>
  );

  return (
    <div
      data-cy={`sentiment-trends-${props.type}`}
      className={classNames({
        [s.bbSentimentCardWrapper]: !fullWidth,
        [s.bbSentimentCardWrapperFullWidth]: fullWidth,
      })}
    >
      <PerformanceCard {...props} size="md" title={getSentimentText()} icon={getSentimentIcon()}>
        <SentimentChart type={props.type} data={props.data} />
        {suffix}
      </PerformanceCard>
    </div>
  );
};

export default SentimentsTrend;
