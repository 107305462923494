import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import React, { FC, useCallback, useEffect } from "react";

import UserDetails from "./UserDetails";
import { Breadcrumbs } from "./Breadcrumbs";
import { Modal, Loading, debounce, ChevronLeftIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { CommentsGridContainer } from "@containers/Comments/Comments";
import { MessageList } from "@components/_common/MessageList/MessageList";
import { CommentDetails } from "@components/_common/CommentDetail/CommentDetails";

import {
  repliesSelector,
  postingReplySelector,
  postedReplySelector,
  repliesCursorSelector,
  fetchingRepliesSelector,
  fetchingThreadRepliesSelector,
} from "@store/replies/selectors";
import {
  getRepliesAction,
  getRepliesPageAction,
  postCommentReplyAction,
  clearRepliesCommentListAction,
  clearReplyingThreadCommentAction,
} from "@store/replies/actions";
import { commentsNavigationIdSelector } from "@store/comments/selectors";

import s from "./ReplyModal.module.less";

import { useReplyModalView } from "./useReplyModalView";
import { useReplyModalContext } from "./ReplyModalContext";
import { keyCodes, useKeyPress } from "@utils/useKeyPress";
import { IReplyModalContentProps, IReplyModalProps, CategoryType } from "./ReplyModal.type";
import { useReplyingComment } from "@utils/comments";

const ReplyModalContent: FC<IReplyModalContentProps> = ({
  replyData,
  filters,
  showLikeAction,
  showLinkedInReactionAction,
  showHideAction,
  showDeleteAction,
  showReviewAction,
  showFlagAction,
  showStarAction,
  showArchiveAction,
  showDetailsAction,
  providedFetchAction,
  onClose,
  comments,
}) => {
  const dispatch = useDispatch();

  const {
    author_detail: { categories },
  } = replyData;

  const { currentView, selectedCategory, setCurrentView, handleChangeCategory } =
    useReplyModalView(categories);
  const comment = useReplyingComment(replyData, comments);

  const commentsNavigationId = useSelector(commentsNavigationIdSelector);
  const isReplyPosting = useSelector(postingReplySelector);
  const repliesFetching = useSelector(fetchingRepliesSelector);
  const repliesCursorValue = useSelector(repliesCursorSelector);

  // eslint-disable-next-line
  const fetchRepliesPage = useCallback(
    debounce(() => {
      if (!repliesFetching && !!repliesCursorValue) {
        dispatch(getRepliesPageAction(repliesCursorValue));
      }
    }, 300),
    [dispatch, repliesFetching, repliesCursorValue],
  );

  const fetchReplies = useCallback(
    commentId => dispatch(getRepliesAction(commentId, commentsNavigationId)),
    [dispatch, commentsNavigationId],
  );

  const postReply = useCallback(
    (
      commentId: string,
      userTag: string,
      message: string,
      is_ignored: boolean,
      replyingAssetId?: string,
      isPrivateReply?: boolean,
    ) => {
      const separator = userTag ? " " : "";

      dispatch(
        postCommentReplyAction({
          message: `${userTag}${separator}${message}`,
          raw_message: message,
          commentId,
          is_ignored,
          replyingAssetId,
          isPrivateReply,
        }),
      );
    },
    [dispatch],
  );

  const handleRefresh = () => {
    if (selectedCategory?.id) {
      localStorage.setItem(
        "SelectedReplyModalState",
        JSON.stringify({
          view: currentView,
          categoryId: selectedCategory?.id,
        }),
      );

      if (selectedCategory?.id === "private_messages")
        fetchReplies(replyData.author_detail.author_id);
      else fetchReplies(replyData.replying_comment_id);
    }
  };

  return (
    <div className={s.bbReplyModalContent}>
      <UserDetails
        replyData={replyData}
        comment={comment}
        selectedCategory={selectedCategory}
        onChangeCategory={handleChangeCategory}
        onRefresh={handleRefresh}
      />
      <div className={s.bbCategoryWrapper}>
        {!!selectedCategory && !(selectedCategory?.id === "reviews") && (
          <div className={s.bbCategoryTitle}>
            <Breadcrumbs
              currentView={currentView}
              onClick={() => setCurrentView("default")}
              authorName={replyData.author_detail.author_name}
              category={selectedCategory?.id as CategoryType}
            />
          </div>
        )}
        <div className={s.bbCategoryBody}>
          {currentView === "details" ? (
            <MessageList
              filters={filters}
              onReply={postReply}
              replyData={replyData}
              isLoading={repliesFetching}
              isReplyBtnLoading={isReplyPosting}
              onBottomReached={fetchRepliesPage}
              onClose={onClose}
              comments={comments}
            />
          ) : (
            <div className={s.bbDefaultListContainer}>
              <CommentsGridContainer
                filters={selectedCategory?.search_filters}
                providedFetchAction={providedFetchAction}
                view="comment_details"
                showLikeAction={showLikeAction}
                showLinkedInReactionAction={showLinkedInReactionAction}
                showHideAction={showHideAction}
                showDeleteAction={showDeleteAction}
                showReviewAction={showReviewAction}
                showFlagAction={showFlagAction}
                showStarAction={showStarAction}
                showDetailsAction={showDetailsAction}
                showArchiveAction={showArchiveAction}
              />
            </div>
          )}
        </div>
      </div>
      {currentView === "details" && !!replyData.post_preview?.id ? (
        <CommentDetails filters={filters} replyData={replyData} comments={comments} />
      ) : (
        <div className={s.bbEmptyDetailsView} />
      )}
    </div>
  );
};

const ReplyModal: FC<IReplyModalProps> = ({
  isOpen,
  filters,
  onClose,
  showLikeAction,
  showHideAction,
  showDeleteAction,
  showLinkedInReactionAction,
  showReviewAction,
  showFlagAction,
  showStarAction,
  showArchiveAction,
  providedFetchAction,
  comments,
}) => {
  const dispatch = useDispatch();
  const { resetTranslation } = useReplyModalContext();

  const commentsNavigationId = useSelector(commentsNavigationIdSelector);
  const replyData = useSelector(repliesSelector);
  const repliesFetching = useSelector(fetchingRepliesSelector);
  const threadRepliesFetching = useSelector(fetchingThreadRepliesSelector);
  const isReplyPosted = useSelector(postedReplySelector);

  const fetchReplies = useCallback(
    commentId => dispatch(getRepliesAction(commentId, commentsNavigationId)),
    [dispatch, commentsNavigationId],
  );

  const clearReplyComments = () => {
    dispatch(clearRepliesCommentListAction());
  };

  const clearReplyingThreadComment = useCallback(
    () => dispatch(clearReplyingThreadCommentAction()),
    [dispatch],
  );

  const nextComment = useCallback(() => {
    const nextCommentId = replyData?.navigation_meta?.next_id;

    if (nextCommentId) {
      resetTranslation();
      fetchReplies(nextCommentId);
      localStorage.removeItem("SelectedReplyModalState");
    }
  }, [fetchReplies, replyData, resetTranslation]);

  const prevComment = useCallback(() => {
    const prevCommentId = replyData?.navigation_meta?.prev_id;

    if (prevCommentId) {
      resetTranslation();
      fetchReplies(prevCommentId);
      localStorage.removeItem("SelectedReplyModalState");
    }
  }, [fetchReplies, replyData, resetTranslation]);

  useKeyPress(keyCodes.ArrowRight, () => {
    nextComment();
  });

  useKeyPress(keyCodes.ArrowLeft, () => {
    prevComment();
  });

  useEffect(() => {
    if (isReplyPosted && localStorage.getItem("AutoNavigation") === "true") {
      nextComment();
    }
    // eslint-disable-next-line
  }, [isReplyPosted]);

  const _onClose = () => {
    onClose();
    resetTranslation();
    clearReplyComments();
    clearReplyingThreadComment();
    localStorage.removeItem("SelectedReplyModalState");
  };

  return (
    <Modal
      centered
      hideFooter
      hideHeader
      width={1216}
      theme="light"
      open={isOpen}
      onCancel={_onClose}
      destroyOnClose={true}
      className={s.bbReplyModal}
    >
      {replyData?.navigation_meta?.prev_id && !repliesFetching && (
        <div
          onClick={prevComment}
          className={classNames(s.bbReplyModalArrow, s.bbReplyModalArrowLeft)}
        >
          <ChevronLeftIcon />
        </div>
      )}
      {repliesFetching || threadRepliesFetching ? (
        <div className={s.bbReplyModalLoading}>
          <Loading type="bb" isLoading />
        </div>
      ) : !!replyData && !!filters ? (
        <ReplyModalContent
          replyData={replyData}
          filters={filters}
          onClose={_onClose}
          comments={comments}
          showLikeAction={showLikeAction}
          showLinkedInReactionAction={showLinkedInReactionAction}
          showHideAction={showHideAction}
          showDeleteAction={showDeleteAction}
          showReviewAction={showReviewAction}
          showFlagAction={showFlagAction}
          showStarAction={showStarAction}
          showArchiveAction={showArchiveAction}
          providedFetchAction={providedFetchAction}
        />
      ) : null}
      {replyData?.navigation_meta?.next_id && !repliesFetching && (
        <div
          onClick={nextComment}
          className={classNames(s.bbReplyModalArrow, s.bbReplyModalArrowRight)}
        >
          <ChevronLeftIcon />
        </div>
      )}
    </Modal>
  );
};

export default ReplyModal;
