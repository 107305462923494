import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import { Layout, Grid } from "antd";
import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../Appfooter/AppFooter";
import AppFilters from "../AppFilter/AppFilters";
import AppSidebar from "../AppSidebar/AppSidebar";
import AppHeaderMobile from "../AppHeader/AppHeaderMobile";

import { clientDataSelector, meSelector } from "@store/me/selectors";
import { predefinedFiltersSelector } from "@store/savedFilters/selectors";
import { filterDataSelector, savedFilterValueSelector } from "@store/filters/selectors";
import { saveFilterAction, triggerFilteringWithoutUrlAction } from "@store/filters/actions";

import s from "./AppLayout.module.less";

import { IAppLayout } from "./AppLayout.types";
import { IFilters } from "@store/filters/types";
import { useAppFilters } from "@utils/appFilters";
import { getCurrentModuleName } from "@utils/paths";
import { getSubFeatureName } from "@utils/feature";
import { getDefaultFilterValue } from "@utils/filters";
import { IFormHandler } from "../AppFilter/Filters.type";
import { selectItem } from "../AppHeader/AppHeaderCommons";
import { ISavedFilter } from "../AppFilter/AppFilters.type";
import { getDefaultFiltersConfig } from "../AppFilter/config";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { removeItemUrlSearch } from "@utils/removeItemUrlSearch";

const { Content } = Layout;

const AppLayout: FC<IAppLayout> = ({
  menu,
  extra,
  children,
  basename,
  hasHeader,
  hasFooter,
  avatarUrl,
  selectedKey,
  defaultSelectedKey,
  onPreRedirect = () => Promise.resolve(),
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const { isBatchModeActive } = useBatchMode();
  const [searchParams] = useSearchParams();
  const FiltersRef = useRef<IFormHandler>(null);

  const subFeatureName = getSubFeatureName();
  const { updateSearchDataAndUrl } = useAppFilters();
  const { content, beforeContent, afterContent } = children;
  const hasNewSidenav = getCurrentModuleName() === "listen";
  const isFooterFixed = window.location.pathname.includes("/settings/asset-management/add-assets");
  const isMobile = !screens.md;

  const [stickyFilterValue, setStickyFilterValue] = useState<string | null>(null);

  const me = useSelector(meSelector);
  const clientData = useSelector(clientDataSelector);
  const filters = useSelector(filterDataSelector);
  const savedFilterValue = useSelector(savedFilterValueSelector);
  const predefinedFilters = useSelector(predefinedFiltersSelector);

  const triggerFilterClick = useCallback(
    (data: IFilters) => dispatch(triggerFilteringWithoutUrlAction(data)),
    [dispatch],
  );

  const setSavedFilterValue = useCallback(
    (id: string | number | null) => dispatch(saveFilterAction(id)),
    [dispatch],
  );

  const getDefaultFilters = useCallback(
    () => getDefaultFilterValue(me),
    // eslint-disable-next-line
    [clientData],
  );

  const props = {
    basename: basename || "",
    selectedKey: selectedKey ? selectedKey : "",
    defaultSelectedKey,
    screens,
    menu,
    extra,
    avatarUrl,
    hideSubMenu: hasNewSidenav,
    onPreRedirect,
  };

  const hasSavedFilters = useMemo(
    () =>
      hasNewSidenav &&
      searchParams.get("section") !== "report-compare" &&
      searchParams.get("section") !== "listen_more-listening_sources" &&
      searchParams.get("section") !== "listen_more-trending_hashtags",
    [hasNewSidenav, searchParams],
  );

  const hasFilters = () => {
    return (
      searchParams.get("section") !== "report-compare" &&
      searchParams.get("section") !== "listen_more-listening_sources" &&
      searchParams.get("section") !== "listen_more-trending_hashtags" &&
      (getCurrentModuleName() === "listen" ||
        [
          "overview",
          "search",
          "posts",
          "dashboard",
          "inbox",
          "smart-inbox",
          "transparency",
        ].includes(subFeatureName))
    );
  };

  const getCustomFilters = () => {
    if (subFeatureName === "posts" || searchParams.get("section") === "report-posts") {
      return getDefaultFiltersConfig(t, me)
        .filter(
          ({ name, type }) =>
            type === "asset" && name !== "visibility_type" && name !== "message_types",
        )
        .map(filter => {
          if (filter.name === "data_range_option") {
            return {
              ...filter,
              label: t("components:posts:filters:date:label"),
              tooltip: t("components:posts:table:tableHeader:tooltips:post"),
            };
          }
          return filter;
        });
    }
    return getDefaultFiltersConfig(t, me);
  };

  const onFilterInternal = (data: IFilters, param?: string | ISavedFilter | undefined) => {
    if (
      subFeatureName === "posts" ||
      searchParams.get("section") === "report-posts" ||
      subFeatureName === "transparency"
    ) {
      triggerFilterClick(data);
    }

    if (subFeatureName !== "posts") {
      updateSearchDataAndUrl(data, param);
    }
  };

  const onSavedStickyChange = (filterId?: string | number) => {
    const savedFilters = predefinedFilters.find(({ id }) => id === "savedFilters");
    const stickyFilters = predefinedFilters.find(({ id }) => id === "stickyFilters");

    const savedFilter = savedFilters?.items.find(
      ({ id: savedFilterId }) => savedFilterId === Number(filterId),
    );
    const stickyFilter = stickyFilters?.items.find(
      ({ id: stickyFilterId }) => stickyFilterId === filterId,
    );

    const filter = savedFilter || stickyFilter || null;
    const param = savedFilter ? ({ saved_filter: filterId } as ISavedFilter) : "id";

    onFilterInternal(filter?.request || getDefaultFilters(), param);
  };

  const onResetFilters = () => {
    setSavedFilterValue(null);
    setStickyFilterValue(null);
    FiltersRef.current?.resetFields();
    removeItemUrlSearch("id");
    onFilterInternal(getDefaultFilters());
  };

  const onSavedFilterChange = (id: string | number | null) => {
    const validId = id === "null" ? null : id;

    if (validId) {
      onSavedStickyChange(validId);
    }
    if (validId === savedFilterValue || validId === null) {
      onResetFilters();
    } else {
      setSavedFilterValue(validId);
    }
  };

  const appFilters = filters && (
    <div
      className={classNames({
        [s.bbFiltersBatchMode]: isBatchModeActive,
      })}
    >
      <AppFilters
        FiltersRef={FiltersRef}
        clientData={clientData}
        initialFilterData={filters}
        hasNewSidenav={hasNewSidenav}
        stickyFilterValue={stickyFilterValue}
        onFilter={onFilterInternal}
        onResetFilters={onResetFilters}
        customFilters={getCustomFilters()}
        onSavedStickyChange={onSavedStickyChange}
        onSavedFilterChange={onSavedFilterChange}
        setStickyFilterValue={setStickyFilterValue}
      />
    </div>
  );

  const appHeader = !screens.lg ? <AppHeaderMobile {...props} /> : <AppHeader {...props} />;

  return (
    <Layout>
      {(hasHeader || hasHeader === undefined) && appHeader}
      <div className={s.bbAppLayoutContentWrapper}>
        {hasNewSidenav && (
          <AppSidebar hasSavedFilters={hasSavedFilters} onSavedFilterChange={onSavedFilterChange} />
        )}
        <Content
          id="layout-content"
          className={classNames(s.bbLayoutContent, {
            [s.bbLayoutContentSmall]: selectedKey && selectItem(menu, selectedKey) ? false : true,
            [s.bbLayoutContentFull]: hasFooter === false,
          })}
        >
          <div>
            {beforeContent}
            {content}
            {afterContent}
            {hasFilters() && isMobile && appFilters}
          </div>
        </Content>
        {hasFilters() && !isMobile && appFilters}
      </div>
      {(hasFooter || hasFooter === undefined) && <AppFooter isFixed={isFooterFixed} />}
    </Layout>
  );
};

export default AppLayout;
